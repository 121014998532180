import Resource from '../../../api/resource';
import request from '../../../utils/request';

class Student extends Resource {
    constructor() {
        super('students_ems');
    }

    home(id) {
        return request({
            url: `/students/dashboard/${id}`,
            method: 'post',
        });
    }

    getEdit(payload) {
        return request({
            url: `/students/Edit/${payload.id}/${payload.details}`,
            method: 'get',
        });
    }

    lookup(payload) {
        return request({
            url: `/lookups/get`,
            method: 'post',
            data: payload
        });
    }

    export(payload) {
        return request({
            url: `/students/export`,
            method: 'post',
            responseType: "blob",
            data: payload
        });
    }

    search(payload) {
        return request({
            url: `/students/search`,
            method: 'post',
            data: payload,
        })
    }

    studentCourses() {
        return request({
            url: `/students/courses`,
            method: 'get',

        })
    }

    show_my_course(offering_id) {
        return request({
            url: `/students/courses/${offering_id}`,
            method: 'get',
        })
    }

    put(id, query, details) {
        return request({
            url: `/${this.uri}${id ? `/${id}` : ''}/${details}`,
            method: 'put',
            data: query,
        })
    }

    transcript(payload) {
        return request({
            url: `/students/${payload.id}/transcript/${payload.lang}`,
            responseType: 'blob',
            data: payload,
            method: 'post',
        })
    }

    MasterCertificate(payload) {
        return request({
            url: `/students/${payload.id}/MasterCertificate/${payload.lang}`,
            responseType: 'blob',
            method: 'get',
        })
    }

    exportMigrationTemplate() {
        return request({
            url: `/students/export_migrated_students_template`,
            method: 'post',
            responseType: "blob",
        });
    }
    certificate(payload) {
        return request({
            url: `/students/${payload.id}/certificate/${payload.lang}`,
            responseType: 'blob',
            method: 'post',
            data: payload,
        })
    }

    exportMigrationTemplate() {
        return request({
            url: `/students/export_migrated_students_template`,
            method: 'post',
            responseType: "blob",
        });
    }

    assignTags(query) {
        return request({
            url: `/tags/attach`,
            method: 'post',
            data: query,
        })
    }

    bulkScholarships(query) {
        return request({
            url: `students/scholarships/bulk-apply-scholarship`,
            method: 'post',
            data: query,
        })
    }

    removeTags(query) {
        return request({
            url: `/tags/remove-student/tags`,
            method: 'post',
            data: query,
        })
    }

    updateNotes(id, query) {
        return request({
            url: `/${this.uri}/update_notes${id ? `/${id}` : ''}`,
            method: 'post',
            data: query,
        })
    }

    programsHistory(id) {
        return request({
            url: `/${this.uri}/programs-history/${id}`,
            method: 'get',
        })
    }

    deleteHistory(id) {
        return request({
            url: `/${this.uri}/programs-history/${id}`,
            method: 'delete',
        })
    }

    bulkSpecialize(payload) {
        return request({
            url: `/${this.uri}/bulk-specialize/${payload.specialization_program}`,
            method: 'post',
            data: payload.query,
        })
    }
}

export {Student as default};
